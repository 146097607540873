import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images';
import './CalendarApp.css';
import { Grid } from '@mui/material';

const CalendarApp = () => {
  const { t } = useTranslation();

  return (
    <div className='calendarApp'>
      <Grid container className='body'>
        <Grid className='desktop' item container xs={12} md={6} justifyContent={'center'}>
          <img src={Images.tiim.calendarApp} alt='calendarApp' />
        </Grid>
        <Grid item container xs={12} md={6} justifyContent={'center'} alignContent={'center'} >
          <h1 >{t(`calendarApp.title`)}</h1>
          <h2 >{t(`calendarApp.description`)}</h2>
          {
            t(`calendarApp.list`).map((item, index) => (
              <Grid className='item' container item alignItems={'center'} key={index} >
                <img className='icon' src={Images.icons.circleCheck} alt='calendar' />
                <span>{item}</span>
              </Grid>
            ))
          }
          <img className='mobile' src={Images.tiim.calendarApp} alt='calendarApp' />
        </Grid>
      </Grid>
    </div>

  )
}

export default CalendarApp 