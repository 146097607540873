
import './Header.css'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Button, Collapse, Divider, Drawer, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Popover } from '@mui/material'
import { changeLanguage } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Images from '../assets/images'

const Header = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(t(`label`).toLowerCase());
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const path = (window.location.hash).replace('#/', '');
  const [anchorEl, setAnchorEl] = useState(null);
  const [list, setList] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'language-popover' : undefined;
  const languages = t(`languages`);



  const goToPage = (page) => {
    if (path === page)
      window.scrollTo(0, 0)
    navigate(page)
  }

  const changeView = (page) => {
    setShowMenu(false);
    goToPage(page)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeLanguage = (lang) => {
    changeLanguage(lang);
    setLanguage(lang);
    handleClose()
  }

  const openList = () => {
    setList(!list);
  };

  const getLanguages = () => {
    return Object.values(languages).map(({ name, flag, type }, index) => (
      <ListItem key={index} className={`language-row ${type === language ? 'active' : ''}`}
        onClick={() => { onChangeLanguage(type); setShowMenu(false); }}
      >
        <ListItemIcon >
          <img className='check' src={Images.suscription.checka} alt='check' />
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    ))

  }


  useEffect(() => {
    onChangeLanguage(navigator.language.includes('US') || navigator.language.includes('en') ? 'en' : 'es');
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <div className="menu-bar">
        <Grid className='menu-bar-content' container  >
          <Grid>
            <Link className="logo-container" >
              <img src={Images.tiim.logo} alt='logo'
                onClick={() => (goToPage(''))}
              />
            </Link>
          </Grid>
          <Grid className='nav-links-container' >
            <Grid container justifyContent="flex-end">
              {
                t(`header.list`).map(({ label, subfix, page, view, email }, i) => (
                  !view &&
                  <div key={`link-${i}`} className={`menu-item desktop ${path === page && 'active'}`}
                    onClick={() => {
                      email ?
                        window.location.href = "mailto:hello@tiimschool.com" :
                        goToPage(page)
                    }}
                  >
                    {label}
                    {subfix && <span><b> {subfix}</b></span>}
                  </div>
                ))
              }
              <Button
                className='button-login desktop'
                variant="contained"
                onClick={() => window.location.href = "https://admin.tiimschool.com"}
              >
                {t(`login`)}
              </Button>
              <Button
                className='button-language desktop'
                variant="contained"
                onClick={handleClick}
              >
                <img src={Images.icons.language} alt='language' />
                {t(`label`)}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className='popper-language'>
                  {
                    Object.values(languages).map(({ name, flag, type }, index) => (
                      <div key={index} className={`language-row ${type === language ? 'active' : ''}`}
                        onClick={() => onChangeLanguage(type)}
                      >
                        <img className='check' src={Images.suscription.checka} alt='check' />
                        {name}
                      </div>
                    ))
                  }
                </div>
              </Popover>
              <div className="menu_button mobile">
                <img className="menu_icon" alt="icon" src={Images.icons.menu}
                  onClick={() => setShowMenu(true)}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Drawer anchor='right' open={showMenu} onClose={() => setShowMenu(false)}>
        <Box sx={{ width: 280 }} role="presentation"
        // onClick={() => setShowMenu(false)}
        // onKeyDown={() => setShowMenu(false)}
        >
          <>
            <h1 className="side_menu_title">{t(`header.title`)}</h1>
            <List className="side_menu_list">
              {
                t(`header.list`).map(({ label, page, section, add, email, subfix, language }, i) => (
                  <div key={`item_${i}`}>
                    <ListItem>
                      <ListItemText
                        primary={`${label} ${subfix ? subfix : ''}`}
                        style={{ paddingLeft: '8px' }}
                        onClick={() => {
                          email ?
                            window.location.href = "mailto:hello@tiimschool.com" :
                            (
                              language ?
                                openList()
                                :
                                changeView(page)

                            )
                        }}
                      />
                      {language && (list ? <ExpandLess /> : <ExpandMore />)}
                    </ListItem>
                    {
                      language &&
                      <Collapse in={list} unmountOnExit>
                        <List component="div" disablePadding>
                          {getLanguages()}
                        </List>
                      </Collapse>
                    }
                    {i !== t(`header`).length - 1 && <Divider />}
                  </div>
                ))
              }
            </List>
          </>
        </Box>
      </Drawer>
    </div>

  )
}

export default Header