import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images';
import './Dashboard.css';
import { Button, Grid } from '@mui/material';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div className='dashboard'>
      <Grid container className='body'>
        <Grid container item xs={12} md={6} justifyContent={'center'} alignContent={'center'} >
          <h1 >{t(`dashboard.title`)}</h1>
          <img className='mobile' src={Images.tiim.dashboard} alt='dashboard' />
          <h2 >{t(`dashboard.description`)}</h2>
          <Button className='button' variant="contained" onClick={() => window.location.href = "mailto:hello@tiimschool.com"}>
            {t(`dashboard.button`)}
          </Button>
        </Grid>
        <Grid className='desktop' container item xs={12} md={6} justifyContent={'center'} alignContent={'center'}>
          <img src={Images.tiim.dashboard} alt='dashboard' />
        </Grid>
      </Grid>
    </div>

  )
}

export default Dashboard 