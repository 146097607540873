const Images = {
    tiim: {
        logo: require('../images/logo.png'),
        logoWhite: require('../images/logoWhite.png'),
        logoTiim: require('../images/logoTiim.png'),
        playstore: require('../images/playStore.png'),
        appstore: require('../images/appStore.png'),
        intro: require('../images/intro.png'),
        school: require('../images/school.png'),
        dashboard: require('../images/dashboard.png'),
        family: require('../images/family.png'),
        homeApp: require('../images/app_home.png'),
        calendarApp: require('../images/app_calendar.png'),
        paymentApp: require('../images/app_payment.png'),
        paymentMethods: require('../images/payment_methods.png'),
        security: require('../images/security.png'),
    },
    benefits: {
        calendar: require('../images/benefits/calendar.png'),
        communication: require('../images/benefits/communication.png'),
        control: require('../images/benefits/control.png'),
        payment: require('../images/benefits/payment.png'),
        kids: require('../images/benefits/kids.png'),
        school: require('../images/benefits/school.png'),
        phone: require('../images/benefits/phone.png'),
    },
    reviews: {
        gustavo: require('../images/reviews/gustavo.png'),
        jimena: require('../images/reviews/jimena.png'),
        jose: require('../images/reviews/jose.png'),
        luis: require('../images/reviews/luis.png'),
        patricia: require('../images/reviews/patricia.png'),
        susana: require('../images/reviews/susana.png'),
    },
    messages: {
        message1: require('../images/message1.webp'),
        message1_en: require('../images/message1_en.webp'),
    },
    create: {
        createTiim: require('../images/create/createTiim.webp'),
        createTiim_en: require('../images/create/createTiim_en.webp'),
        createTiimMobile: require('../images/create/createTiimMobile.webp'),
    },
    organize: {
        screen1: require('../images/organize/screen1.webp'),
        screen1_en: require('../images/organize/screen1_en.webp'),
        crown: require('../images/organize/crown.webp'),
        document: require('../images/organize/document.webp'),
        health: require('../images/organize/health.webp'),
        insurance: require('../images/organize/insurance.webp'),
        shopping: require('../images/organize/shopping.webp'),
        trip: require('../images/organize/trip.webp'),
        ss_health: require('../images/organize/ss_health.webp'),
        ss_finances: require('../images/organize/ss_finances.webp'),
        ss_document: require('../images/organize/ss_document.webp'),
        ss_trip: require('../images/organize/ss_trip.webp'),
        ss_shopping: require('../images/organize/ss_shopping.webp'),
        ss_health_en: require('../images/organize/ss_health_en.webp'),
        ss_finances_en: require('../images/organize/ss_finances_en.webp'),
        ss_document_en: require('../images/organize/ss_document_en.webp'),
        ss_trip_en: require('../images/organize/ss_trip_en.webp'),
        ss_shopping_en: require('../images/organize/ss_shopping_en.webp'),
    },
    bills:{
        bills: require('../images/bills/bills.webp'),
        bills_en: require('../images/bills/bills_en.webp'),
    },
    events:{
        calendar: require('../images/events/calendar.webp'),
        calendar_en: require('../images/events/calendar_en.webp'),
    },
    profile:{
        profile: require('../images/profiles/profile.webp'),
        profile_en: require('../images/profiles/profile_en.webp'),
    },
    icons: {
        language: require('../images/language.png'),
        menu: require('../images/menu.png'),
        circleCheck: require('../images/icons/circleCheck.png'),
        facebook: require('../images/icons/facebook.png'),
        instagram: require('../images/icons/instagram.png'),
        linkedin: require('../images/icons/linkedin.png'),
        twitter: require('../images/icons/twitter.png'),
        downArrow: require('../images/icons/downArrow.webp'),
        upArrow: require('../images/icons/upArrow.webp'),
        checkSquare: require('../images/icons/check-square.webp'),
        play: require('../images/icons/play.png'),
    },
    aboutUs:{
        dadAndKid: require('../images/dadAndKid.webp'),
        aboutNotification1: require('../images/about/about-notification-1.webp'),
        aboutNotification1_en: require('../images/about/about-notification-1_en.webp'),
        family: require('../images/about/family.webp'),
        shield: require('../images/about/shield.webp'),
    },
    securtity: {
        securityMan: require('../images/security/security-man.webp'),
        antiHacker: require('../images/security/antiHacker.webp'),
        sslIcon: require('../images/security/ssl-icon.webp'),
        securityFamily: require('../images/security/security-family.webp'),
    },
    suscription:{
        momAndKid: require('../images/suscription/mom-and-kid.webp'),
        iconCrown: require('../images/suscription/iconCrown.webp'),
        iconHeart: require('../images/suscription/iconHeart.webp'),
        iconStar: require('../images/suscription/iconStar.webp'),
        checka: require('../images/suscription/checka.webp'),
        family4: require('../images/suscription/family-4.webp'),
    },
    faq: {
        familyAsk: require('../images/faq/family-ask.webp'),
    },
    flags: {
        mexico: require('../images/flags/mexico.webp'),
        usa: require('../images/flags/usa.webp'),
    }
};

export default Images;