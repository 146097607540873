import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images';
import './School.css';
import { Button, Grid } from '@mui/material';

const School = () => {
  const { t } = useTranslation();

  return (
    <div className='school'>
      <Grid container className='body'>
        <Grid className='desktop' item container xs={12} md={6} justifyContent={'center'} alignContent={'center'} pr={15}>
          <img src={Images.tiim.school} alt='school' />
        </Grid>
        <Grid container item xs={12} md={6} justifyContent={'center'} alignContent={'flex-start'} >
          <label>{t(`school.label`)}</label>
          <h1 >{t(`school.title`)}</h1>
          <img className='mobile' src={Images.tiim.school} alt='school' />
          <h2 >{t(`school.description`)}</h2>
          <Button className='button' variant="contained" onClick={() => window.location.href = "mailto:hello@tiimschool.com"}>
            {t(`school.button`)}
          </Button>
        </Grid>
      </Grid>
    </div>

  )
}

export default School 