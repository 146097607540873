import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images';
import './Reviews.css';
import { Grid, Paper } from '@mui/material';

const Reviews = () => {
  const { t } = useTranslation();

  return (
    <div className='reviews'>
      <h1>
        {t(`reviews.title1`)}
        <img src={Images.tiim.logoTiim} alt={'Tiim'} />
        {t(`reviews.title2`)}
      </h1>
      <h2>{t(`reviews.subtitleParents`)}</h2>
      <Grid container spacing={4}>
        {
          t(`reviews.parents`).map((item, index) => (
            <Grid key={index} item xs={12} md={4} justifyContent={'space-between'} >
              <Paper className='card' elevation={2} >
                <Grid container mb={2}>
                  <Grid item>
                    <img className='card-image' src={Images.reviews[item.image]} alt={item.title} />
                  </Grid>
                  <Grid item alignContent={'center'} flex={1}>
                    <span className='card-name'>{item.name}</span>
                    <span className='card-location'>{item.location}</span>
                  </Grid>
                </Grid>
                <span className='card-description'>{item.description}</span>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
      <h2>{t(`reviews.subtitleTeachers`)}</h2>
      <Grid container spacing={4}>
        {
          t(`reviews.teachers`).map((item, index) => (
            <Grid key={index} item xs={12} md={4} justifyContent={'space-between'} >
              <Paper className='card' elevation={2} >
                <Grid container mb={2}>
                  <Grid item>
                    <img className='card-image' src={Images.reviews[item.image]} alt={item.title} />
                  </Grid>
                  <Grid item alignContent={'center'} flex={1}>
                    <span className='card-name'>{item.name}</span>
                    <span className='card-location'>{item.location}</span>
                  </Grid>
                </Grid>
                <span className='card-description'>{item.description}</span>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </div>

  )
}

export default Reviews 