import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images';
import './PaymentApp.css';
import { Grid } from '@mui/material';

const PaymentApp = () => {
  const { t } = useTranslation();

  return (
    <div className='paymentApp'>
      <Grid container className='body'>
        <Grid item container xs={12} md={6} justifyContent={'center'} alignContent={'center'} >
          <h1 >{t(`paymentApp.title`)}</h1>
          <h2 >{t(`paymentApp.description`)}</h2>
          <img className='paymentMethods desktop' src={Images.tiim.paymentMethods} alt='Play Store' />
        </Grid>
        <Grid item container xs={12} md={6} justifyContent={'center'} alignContent={'center'}>
          <img src={Images.tiim.paymentApp} alt='paymentApp' />
          <img className='paymentMethods mobile' src={Images.tiim.paymentMethods} alt='Play Store' />
        </Grid>
      </Grid>
    </div>

  )
}

export default PaymentApp 