import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images';
import './Intro.css';
import { Button, Grid } from '@mui/material';
import ModalVideo from '../common/ModalVideo';

const Intro = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Grid container className='intro'>
        <Grid item xs={12} md={6} justifyContent={'center'} alignContent={'center'}>
          <h1 >{t(`intro.title`)}</h1>
          <img className='mobile' src={Images.tiim.intro} alt='logo' />
          <h2 >{t(`intro.description`)}</h2>
          <Grid container item>
            <Grid container item xs={12} >
              <Button className='button' variant="contained" onClick={() => window.location.href = "mailto:hello@tiimschool.com"}>
                {t(`intro.demo`)}
              </Button>
              <img className="desktop play-icon" alt="" src={Images.icons.play} onClick={() => setShowModal(true)} />
            </Grid>
            <Grid container item xs={12}>
              <img className="mobile play-icon" alt="" src={Images.icons.play} onClick={() => setShowModal(true)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6} className='desktop' justifyContent={'center'} alignContent={'center'}>
          <img src={Images.tiim.intro} alt='logo' />
        </Grid>
      </Grid>
      <div className="wrap-modal-video">
        {showModal &&
          <ModalVideo
            Id={t(`videoID`)}
            setShowModal={setShowModal}
          />
        }
      </div>
    </>
  )
}

export default Intro 