import React, { useEffect } from 'react'
import { Intro, SchoolBenefits, School, Dashboard, ParentsBenefits, Family, HomeApp, CalendarApp, PaymentApp, Security, Reviews } from '../../component';

const Main = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Intro />
      <SchoolBenefits />
      <School />
      <Dashboard />
      <ParentsBenefits />
      <Family />
      <HomeApp />
      <CalendarApp />
      <PaymentApp />
      <Security />
      <Reviews />
    </>
  )
}

export default Main