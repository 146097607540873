import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images';
import './ParentsBenefits.css';
import { Grid, Paper } from '@mui/material';

const ParentsBenefits = () => {
  const { t } = useTranslation();

  return (
    <div className='parentsBenefits'>
      <h1>{t(`parentsBenefits.title`)}</h1>
      <h2>{t(`parentsBenefits.subtitle`)}</h2>
      <Grid container spacing={5}>
        {
          t(`parentsBenefits.cards`).map((item, index) => (
            <Grid key={index} item xs={12} md={4} justifyContent={'space-between'} >
              <Paper className='card' elevation={2} >
                <img className='card-image' src={Images.benefits[item.image]} alt={item.title} />
                <span className='card-title'>{item.title}</span>
                <span className='card-description'>{item.description}</span>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </div>

  )
}

export default ParentsBenefits 