import React from 'react'
import { Route, Routes, R } from 'react-router-dom'
import { Main, UniversalLink } from '../component'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/universal-link" element={<UniversalLink />} />
    </Routes>
  )
}

export default PrivateRoutes