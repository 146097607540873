import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images';
import './Security.css';
import { Button, Grid } from '@mui/material';

const Security = () => {
  const { t } = useTranslation();

  return (
    <div className='security'>
      <Grid container className='body'>
        <Grid className='desktop' item container xs={12} md={6} justifyContent={'center'} alignContent={'center'}>
          <img src={Images.tiim.security} alt='security' />
        </Grid>
        <Grid item container xs={12} md={6} justifyContent={'center'} alignContent={'center'} >
          <label>{t(`security.label`)}</label>
          <h1 >{t(`security.title`)}</h1>
          <img className='mobile' src={Images.tiim.security} alt='security' />
          <h2 >{t(`security.description`)}</h2>
          <Button className='button' variant="contained" onClick={() => window.location.href = "mailto:hello@tiimschool.com"}>
            {t(`security.button`)}
          </Button>
        </Grid>
      </Grid>
    </div>

  )
}

export default Security 