import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images';
import './SchoolBenefits.css';
import { Grid, Paper } from '@mui/material';

const SchoolBenefits = () => {
  const { t } = useTranslation();

  return (
    <div className='benefits'>
      <h1>{t(`benefits.title`)}</h1>
      <Grid container spacing={5}>
        {
          t(`benefits.cards`).map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}  justifyContent={'space-between'} >
              <Paper className='card' elevation={2}>
                <img className='card-image' src={Images.benefits[item.image]} alt={item.title} />
                <span className='card-title'>{item.title}</span>
                <span className='card-description'>{item.description}</span>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </div>

  )
}

export default SchoolBenefits 