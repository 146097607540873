import { useEffect } from "react";

const UniversalLink = () => {
    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }
    const detectAndServe = () => {
        let os = getMobileOperatingSystem();
        if (os === "Android") {
            window.location.href = "https://play.google.com/store/apps/details?id=com.tiimapp";
        } else if (os === "iOS") {
            window.location.href = "https://apps.apple.com/mx/app/tiim/id6466341070";
        } else {
            window.location.href = "https://tiimschool.com/";
        }
    }
    useEffect(() => {
        detectAndServe();
        // eslint-disable-next-line
    }, [])

    return (<p>Tiim School App</p>)
}


export default UniversalLink;