import React from 'react'
import { useTranslation } from 'react-i18next';
import Images from '../../assets/images';
import './HomeApp.css';
import { Grid } from '@mui/material';

const HomeApp = () => {
  const { t } = useTranslation();

  return (
    <div className='homeApp'>
      <Grid container className='body'>
        <Grid item container xs={12} md={6} justifyContent={'center'} alignContent={'center'}  >
          <h1 >{t(`homeApp.title`)}</h1>
          <h2 >{t(`homeApp.description`)}</h2>
          <img className='mobile' src={Images.tiim.homeApp} alt='homeApp' />
          <div className='stores'>
            <img className='store' src={Images.tiim.playstore} alt='Play Store' />
            <img className='store' src={Images.tiim.appstore} alt='App Store' />
          </div>
          <span>{t(`homeApp.info`)}</span>
        </Grid>
        <Grid className='desktop' item container xs={12} md={6} justifyContent={'center'} alignContent={'center'}>
          <img src={Images.tiim.homeApp} alt='homeApp' />
        </Grid>
      </Grid>
    </div>

  )
}

export default HomeApp 